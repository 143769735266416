import React from "react";

function Features1({ img, heading, text, isReverse }) {
  return (
    <section
      className={`w-full my-16 lg:my-10 flex flex-wrap ${
        isReverse ? "flex-row-reverse" : "flex-row"
      } `}
    >
      <div className="w-full lg:w-1/2  lg:h-[500px]  h-[350px] ">
        <img src={img} alt="" className="h-full w-full" />
      </div>
      <div className="w-full lg:w-1/2 my-12 flex items-center justify-center  ">
        <div className="text-center ">
          <h3 className="text-3xl lg:text-4xl font-medium">{heading}</h3>
          <p className=" font-light mt-5 px-2">{text}</p>
        </div>
      </div>
    </section>
  );
}

export default Features1;
