import React from "react";
import Container from "./Container";
import { FaTwitter, FaInstagram } from "react-icons/fa";

function Footer() {
  return (
    <footer className="bg-green-500 mt-24 text-white">
      <Container>
        <div className="flex flex-wrap justify-between px-3 py-5 ">
          <p>2022 Inviteam</p>

          <div className="flex gap-x-5 text-lg">
            <FaInstagram />
            <FaTwitter />
          </div>
        </div>
      </Container>
    </footer>
  );
}

export default Footer;
