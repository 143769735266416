import React from "react";

function Container({ children }) {
  return (
    <>
      <section className="relative  mx-auto h-full w-full max-w-[1300px]  ">
        {children}
      </section>
    </>
  );
}

export default Container;
