import React from "react";
import phones from "../images/phones.png";
import googlebadge from "../images/googlebadge.png";
import applebadge from "../images/applebadge.png";
import Container from "../components/Container";

import circleImg1 from "../images/circleimg (1).png";
import circleImg2 from "../images/circleimg (2).png";
import circleImg3 from "../images/circleimg (3).png";

import fea1 from "../images/fea (1).png";
import fea2 from "../images/fea (2).png";
import fea3 from "../images/fea (3).png";

import private_party from "../images/private_party.png";

import Features1 from "../components/Features1";
import Features2 from "../components/Features2";

import phones5 from "../images/phones5.png";

function Home() {
  const firstFeaturesSection = [
    {
      text: "Attend opened events and create a network for yourself",
      heading: "SELF INVITATIONS",
      img: fea1,
    },
    {
      text: "Securely authenticate during events",
      heading: "VERIFY GUESTS",
      img: fea2,
    },
    {
      text: "Get guests to make purchases you choose for your events.",
      heading: "WISHLISTS",
      img: fea3,
    },
  ];
  const secondFeaturesSection = [
    {
      text: "Bring excitement before and after the event as the guest will be surprising the host.",
      heading: "e - ENVELOPE",
      img: private_party,
    },
    {
      text: " Guests can pick the food they desire to eat on the menu, as food is the joy of an event.",
      heading: "FOOD MENU",
      img: private_party,
    },
    {
      text: "Have a wonderful photo gallery of your event that can be shared on social media.",
      heading: "EVENT GALLERY",
      img: private_party,
    },
    {
      text: " Spray the host as much money on the app as  you want.",
      heading: "e - SPRAY",
      img: private_party,
    },
    {
      text: "Collect events fees from guests with ease on the app.",
      heading: "PAID INVITATIONS",
      img: private_party,
    },
    {
      text: " Good-looking access cards that make your event look its best.",
      heading: "DYNAMIC ACCESS CARD",
      img: private_party,
    },
    {
      text: "Keep your event secure as only invited people with Access cards will be allowed In.",
      heading: "PRIVATE PARTY",
      img: private_party,
    },
    {
      text: " Get access to vendors easily, and have options to choose the vendor that suits him/her.",
      heading: "BE A VENDOR",
      img: private_party,
    },
  ];
  return (
    <main className="h-full  ">
      <section className="w-full h-full bg-[#EDF1FD] ">
        <section className="flex flex-wrap max-w-[1300px] mx-auto lg:h-[89.3vh] p-3 lg:mt-0 mt-32">
          <div className="flex items-center justify-center flex-col lg:w-1/2 w-full">
            <section className="lg:text-left text-center px-5">
              <h1 className="text-5xl font-semibold">
                Latest Events at you finger tips,Everyday!
              </h1>
              <p className="mt-5 mb-10">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Suscipit sint nulla sed, quis labore molestias delectus rerum
                ea, eius optio nostrum error dignissimos magnam, ut aliquam in
                laudantium reiciendis? Repellendus!
              </p>
              <div className="flex justify-center lg:justify-start  flex-wrap items-center gap-3">
                <a href="https://play.google.com/store/apps/details?id=com.justwebtech.inviteam">
                  <img
                    src={googlebadge}
                    alt="google badge"
                    className="hover:opacity-80"
                  />
                </a>
                <a href="">
                  <img
                    src={applebadge}
                    alt="apple badge"
                    className="hover:opacity-80"
                  />
                </a>
              </div>
            </section>
          </div>

          <div className="flex  items-center lg:justify-end justify-center lg:w-1/2 w-full">
            <img
              src={phones}
              alt="imviteam app mockps"
              className="w-[90%] h-[90%] object-contain"
            />
          </div>
        </section>
      </section>

      <section className=" bg-[#F7FAFF] " id="features">
        <div className=" p-3  max-w-[1300px] mx-auto">
          <h2 className="font-semibold text-5xl mt-16 mb-12  text-center ">
            FEATURES{" "}
          </h2>

          {firstFeaturesSection.map(({ img, text, heading }, index) => (
            <Features1
              img={img}
              heading={heading}
              text={text}
              isReverse={index == 1 ? true : false}
            />
          ))}

          <div className="mt-16 flex flex-wrap items-center p-3 gap-y-10">
            {secondFeaturesSection.map(({ img, text, heading }, index) => (
              <Features2
                img={img}
                heading={heading}
                text={text}
                isReverse={index == 1 ? true : false}
              />
            ))}
          </div>
        </div>
      </section>

      <Container>
        <div className="text-center my-16 px-2">
          <img
            src={phones5}
            className="object-contain w-full max-h-[90vh] "
            alt=""
          />

          <div className="flex justify-center w-full flex-wrap items-center gap-3">
            <a href="https://play.google.com/store/apps/details?id=com.justwebtech.inviteam">
              <img
                src={googlebadge}
                alt="google badge"
                className="hover:opacity-80 "
              />
            </a>
            <a href="">
              <img
                src={applebadge}
                alt="apple badge"
                className="hover:opacity-80"
              />
            </a>
          </div>
        </div>
      </Container>
    </main>
  );
}

export default Home;
