import React, { useEffect, useState, useRef } from "react";
import { res } from "../helpers/res";
import Container from "../components/Container";
import img from "../images/img.png";
import img1 from "../images/fea (1).png";
import img2 from "../images/fea (2).png";
import img3 from "../images/fea (3).png";

import { GoLocation } from "react-icons/go";
import { GiTicket } from "react-icons/gi";
import {
  FaBalanceScale,
  FaTwitter,
  FaInstagram,
  FaInfoCircle,
} from "react-icons/fa";
import {
  AiOutlineCalendar,
  AiOutlineCheckCircle,
  AiOutlineTag,
  AiOutlineUser,
  AiOutlineLink,
} from "react-icons/ai";
import { Link } from "react-router-dom";
import EventTabs from "../components/EventTabs";
import EventImages from "../components/EventImages";
import Owners from "../components/Owners";
import Download from "../components/Download";
import Modal from "../components/Modal";
import ImageSlider from "../components/ImageSlider";

function Events() {
  const [eventDetails, setEventDetails] = useState([]);

  // async function getEventDetails() {

  //   const a = await fetch(
  //     "https://inviteam.justwebtech.com/api/public/event/1"
  //   );
  //   const b = await a.json();
  //   console.log(b);
  // }

  const { data } = res;
  const {
    admin,
    images,
    env,
    owners,
    mainowner,
    wishlist,
    envelops,
    sprays,
    vendors,
    invites,
  } = data;
  // console.log(data);

  const imgs = [img1, img2, img];

  return (
    <main>
      <section
        className="h-[90vh] bg-no-repeat bg-center relative overflow-hidden	 bg-cover"
        // style={{
        //   backgroundImage: `url('${imgs[currentSliderItem]}')`,
        // }}
      >
        {/* <ImageSlider images={imgs} /> */}

        <div className="w-full h-full bg-black relative bg-opacity-40 z-30 flex items-center justify-center">
          <div className=" absolute left-10 bottom-10 flex items-center font-light italic text-xs lg:text-sm  text-white">
            <div className="w-2 h-2 rounded-full bg-white mr-2  "></div>{" "}
            {env.image.specs}
          </div>
          <article className="max-w-[700px] font-light text-center">
            <h1 className=" text-white mb-8 mt-5 lg:text-7xl  md:text-5xl text-4xl  font-semibold ml-2">
              {env.title}
            </h1>
            <div className="flex justify-center gap-5 text-sm">
              <p className="flex items-center text-white ">
                {" "}
                <AiOutlineCalendar className=" text-2xl mr-2 text-green-400" />
                {env.eventDate.slice(0, 10)}{" "}
              </p>
              <p className="flex items-center text-white ">
                {" "}
                <GoLocation className=" text-2xl mr-2 text-green-400" />
                <p>
                  {env.state}, {env.country}
                </p>
              </p>
            </div>
            <p className="sm:flex-row flex-col uppercase w-full flex items-center justify-center my-10 gap-5">
              <a
                href="#details"
                className="flex gap-3 sm:w-fit justify-center w-full items-center border px-6 py-3 border-3 border-white hover:text-green-600 font-medium text-sm hover:bg-white transition-all ease-in-out text-white"
              >
                <FaInfoCircle /> <span>Details</span>
              </a>
              {env.isSelfInvite ? (
                <Link
                  to=""
                  className="px-6 py-3 flex sm:w-fit gap-2 justify-center w-full items-center  hover:text-white font-medium text-sm text-white bg-green-500 hover:bg-green-600  transition-all ease-in-out "
                >
                  <GiTicket /> <span>Buy Tickets</span>
                </Link>
              ) : null}
            </p>
          </article>
        </div>
      </section>
    </main>
  );
}

export default Events;
