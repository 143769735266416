import "./App.css";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
// import Events from "./pages/Events";
import Events2 from "./pages/Events2";

function App() {
  return (
    <>
      <Navbar />
      <Routes>
        <Route exact path="/" element={<Home />}></Route>

        {/* <Route exact path="/events" element={<Events />}></Route> */}
        <Route exact path="/events2" element={<Events2 />}></Route>
      </Routes>
      <Footer />
    </>
  );
}

export default App;
