import React from "react";

function Features2({ img, text, heading }) {
  return (
    <div className="w-full lg:w-1/2 lg:p-5 p-2 ">
      <div className="flex-1 text-center bg-zinc-200 bg-opacity-50 pt-10 rounded-[1rem]">
        <h3 className="text-2xl  font-medium px-2">{heading}</h3>
        <p className=" font-light mt-3 mb-5 max-w-sm mx-auto">{text}</p>
        <img
          src={img}
          alt=""
          className="h-[250px] lg:h-[350px]  object-contain mx-auto"
        />
      </div>
    </div>
  );
}

export default Features2;
