export const res = {
  code: 0,
  data: {
    env: {
      id: 1,
      address: "62 Ijaiye road Ogba-Ikeja",
      country: "Nigeria",
      createAs: "Planner",
      createDate: "2021-09-12T15:08:43.92",
      eventDate: "2022-05-27T13:39:49.99",
      eventDesc: "Family of Taye Sholarin and family of Kemi Olunloyo",
      eventTag: "TayeKemi2021",
      isComment: true,
      isDmpublic: true,
      isPublic: true,
      isSelfInvite: true,
      maxInvites: 130,
      memberId: 2,
      selfInviteMax: 20,
      specs: "RSVP 08055065202, cloud green",
      state: "Lagos",
      status: "open",
      statusReason: "open",
      title: "Taye Marry Kemi",
      type: "Wedding",
      longitude: null,
      latitude: null,
      allowEnvelop: false,
      allowSpray: true,
      isPayment: true,
      isOwnerPay: false,
      image: {
        id: 1002,
        isFeature: true,
        createdDate: "2021-11-29T15:16:45.877",
        imgPath: "1-pix-202111291516.png",
        specs: "Fine moment",
        isPublic: true,
      },
      inviteTypes: [
        {
          createDate: "2022-05-18T13:54:29.193",
          id: 1,
          amount: 140000.0,
          note: "3 seater Queen corner",
          title: "Queens Corner",
        },
        {
          createDate: "2022-05-18T14:42:23.357",
          id: 3,
          amount: 4000.0,
          note: "seat for small chops",
          title: "Regular",
        },
        {
          createDate: "2022-05-18T14:42:23.313",
          id: 4,
          amount: 12000.0,
          note: "for all senior guys",
          title: "Senior seat",
        },
      ],
    },
    owners: [
      {
        canAddImages: false,
        canAddOwner: false,
        canAddVendor: false,
        canInvite: true,
        canModify: true,
        canSeeInviteList: true,
        canUnInvite: false,
        canVerify: true,
        createDate: "2021-09-17T00:02:35.197",
        createdBy: 2,
        eventId: 1,
        id: 1,
        ownerAs: "",
        isPublic: true,
        member: {
          name: "Temitayo Ojo",
          nickName: null,
          path: null,
          twitterHandle: null,
          ighandle: null,
          specs: null,
          id: 3,
        },
      },
      {
        canAddImages: true,
        canAddOwner: true,
        canAddVendor: false,
        canInvite: true,
        canModify: true,
        canSeeInviteList: true,
        canUnInvite: true,
        canVerify: true,
        createDate: "2021-09-17T00:05:55.383",
        createdBy: 2,
        eventId: 1,
        id: 2,
        ownerAs: "",
        isPublic: true,
        member: {
          name: "Mr Essien",
          nickName: null,
          path: null,
          twitterHandle: null,
          ighandle: null,
          specs: null,
          id: 4,
        },
      },
      {
        canAddImages: true,
        canAddOwner: true,
        canAddVendor: false,
        canInvite: true,
        canModify: true,
        canSeeInviteList: true,
        canUnInvite: true,
        canVerify: true,
        createDate: "2021-09-17T00:07:18.81",
        createdBy: 2,
        eventId: 1,
        id: 3,
        ownerAs: "",
        isPublic: true,
        member: {
          name: "Mr Essien",
          nickName: null,
          path: null,
          twitterHandle: null,
          ighandle: null,
          specs: null,
          id: 4,
        },
      },
      {
        canAddImages: true,
        canAddOwner: true,
        canAddVendor: true,
        canInvite: true,
        canModify: true,
        canSeeInviteList: true,
        canUnInvite: true,
        canVerify: true,
        createDate: "2021-09-17T00:24:03.603",
        createdBy: 2,
        eventId: 1,
        id: 4,
        ownerAs: "",
        isPublic: true,
        member: {
          name: "Mr Essien",
          nickName: null,
          path: null,
          twitterHandle: null,
          ighandle: null,
          specs: null,
          id: 5,
        },
      },
      {
        canAddImages: false,
        canAddOwner: false,
        canAddVendor: false,
        canInvite: false,
        canModify: false,
        canSeeInviteList: false,
        canUnInvite: false,
        canVerify: true,
        createDate: "2021-09-17T00:24:53.713",
        createdBy: 2,
        eventId: 1,
        id: 5,
        ownerAs: "",
        isPublic: false,
        member: {
          name: "Mr Balogun",
          nickName: null,
          path: null,
          twitterHandle: null,
          ighandle: null,
          specs: null,
          id: 6,
        },
      },
    ],
    images: [
      {
        createdBy: 2,
        createdDate: "2021-09-14T18:54:37.607",
        eventId: 1,
        id: 1,
        imgPath: "1-pix-202109141854.png",
        isFeature: false,
        isPublic: true,
        specs: "My guy with swag with semi swags",
      },
      {
        createdBy: 2,
        createdDate: "2021-09-14T18:55:11.243",
        eventId: 1,
        id: 2,
        imgPath: "1-pix-202109141855.png",
        isFeature: false,
        isPublic: true,
        specs: "My guy 2",
      },
      {
        createdBy: 2,
        createdDate: "2021-09-14T19:05:54.107",
        eventId: 1,
        id: 3,
        imgPath: "1-pix-20210914195.png",
        isFeature: false,
        isPublic: true,
        specs: "Storing the last guy",
      },
      {
        createdBy: 2,
        createdDate: "2021-11-29T15:16:45.877",
        eventId: 1,
        id: 1002,
        imgPath: "1-pix-202111291516.png",
        isFeature: true,
        isPublic: true,
        specs: "Fine moment",
      },
    ],
    count: 5,
    right: "admin",
    admin: {
      id: 2,
      name: "Rasheed Ashimolowo",
      nickName: "Al-Jabal",
      path: "2-pix-20211201751.png",
      twitterHandle: "iamthe_jabal",
      ighandle: "iamthe_jabal",
      specs: "CEO Justweb Technology",
      createAs: "Planner",
    },
    wishlist: [
      {
        id: 5,
        allowExcess: true,
        countExpected: 2,
        countReceived: 0,
        createDate: "2021-09-30T08:21:08.247",
        createdBy: 0,
        eventId: 1,
        isItemOnCatalog: false,
        isPublic: true,
        itemId: 8,
        itemPrice: 570000.0,
        itemUrl:
          "https://www.jumia.com.ng/apple-iphone-11-pro-max-6.5-super-retina-4gbram-256gb-romios-1312mp12mp12mp12mp-4g-lte-midnight-green-42147858.html",
        note: "I will love to have the phone",
        item: {
          id: 8,
          category: "Phone",
          name: "IPhone X",
          description:
            "Apple Iphone X 64gb Silver With Free Pouch And Tempered Glass",
          imgPath:
            "https://ng.jumia.is/unsafe/fit-in/500x500/filters:fill(white)/product/76/745033/1.jpg?5275",
        },
      },
      {
        id: 6,
        allowExcess: true,
        countExpected: 1,
        countReceived: 0,
        createDate: "2021-09-30T14:04:11.367",
        createdBy: 2,
        eventId: 1,
        isItemOnCatalog: false,
        isPublic: true,
        itemId: 8,
        itemPrice: 550000.0,
        itemUrl:
          "https://www.jumia.com.ng/apple-iphone-11-pro-max-6.5-super-retina-4gbram-256gb-romios-1312mp12mp12mp12mp-4g-lte-midnight-green-42147858.html",
        note: "Second for my wife Gold",
        item: {
          id: 8,
          category: "Phone",
          name: "IPhone X",
          description:
            "Apple Iphone X 64gb Silver With Free Pouch And Tempered Glass",
          imgPath:
            "https://ng.jumia.is/unsafe/fit-in/500x500/filters:fill(white)/product/76/745033/1.jpg?5275",
        },
      },
      {
        id: 1007,
        allowExcess: true,
        countExpected: 2,
        countReceived: 0,
        createDate: "2021-12-14T14:04:38.673",
        createdBy: 2,
        eventId: 1,
        isItemOnCatalog: true,
        isPublic: true,
        itemId: 1008,
        itemPrice: 520000.0,
        itemUrl:
          "https://www.jumia.com.ng/latitude-3310-2-in-1-core-i5-256gb-ssd-8gb-win10-pro-dell-mpg1549161.html",
        note: "I want a 2 in 1, I like it",
        item: {
          id: 1008,
          category: "Computing",
          name: "DELL Latitude 3310",
          description:
            "DELL Latitude 3310 2-IN-1 Core I5 256GB SSD 8GB WIN10 Pro",
          imgPath:
            "https://ng.jumia.is/unsafe/fit-in/500x500/filters:fill(white)/product/03/114566/1.jpg?4986",
        },
      },
    ],
    envelops: [
      {
        id: 1,
        amount: 50000.0,
        createDate: "2021-10-07T06:30:25.743",
        eventId: 1,
        isOpen: false,
        note: "West",
        openDate: null,
        receiverId: 3,
        senderId: 2,
        sender: {
          phone: "2348055065202",
          email: "afriprog@gmail.com",
          ighandle: "iamthe_jabal",
          twitterHandle: "iamthe_jabal",
          name: "Rasheed Ashimolowo",
          nickName: "Al-Jabal",
          path: "2-pix-20211201751.png",
        },
        receiver: {
          phone: "2347069494849",
          email: null,
          ighandle: null,
          twitterHandle: null,
          name: "Temitayo Ojo",
          nickName: null,
          path: null,
        },
      },
      {
        id: 2,
        amount: 50000.0,
        createDate: "2021-10-07T06:31:55.377",
        eventId: 1,
        isOpen: false,
        note: "West",
        openDate: null,
        receiverId: 3,
        senderId: 2,
        sender: {
          phone: "2348055065202",
          email: "afriprog@gmail.com",
          ighandle: "iamthe_jabal",
          twitterHandle: "iamthe_jabal",
          name: "Rasheed Ashimolowo",
          nickName: "Al-Jabal",
          path: "2-pix-20211201751.png",
        },
        receiver: {
          phone: "2347069494849",
          email: null,
          ighandle: null,
          twitterHandle: null,
          name: "Temitayo Ojo",
          nickName: null,
          path: null,
        },
      },
      {
        id: 3,
        amount: 5000.0,
        createDate: "2021-10-07T06:33:45.653",
        eventId: 1,
        isOpen: false,
        note: "Happy birthday",
        openDate: null,
        receiverId: 3,
        senderId: 2,
        sender: {
          phone: "2348055065202",
          email: "afriprog@gmail.com",
          ighandle: "iamthe_jabal",
          twitterHandle: "iamthe_jabal",
          name: "Rasheed Ashimolowo",
          nickName: "Al-Jabal",
          path: "2-pix-20211201751.png",
        },
        receiver: {
          phone: "2347069494849",
          email: null,
          ighandle: null,
          twitterHandle: null,
          name: "Temitayo Ojo",
          nickName: null,
          path: null,
        },
      },
      {
        id: 4,
        amount: 5050.0,
        createDate: "2021-10-07T06:36:05.743",
        eventId: 1,
        isOpen: false,
        note: "Happy birthday's",
        openDate: null,
        receiverId: 3,
        senderId: 2,
        sender: {
          phone: "2348055065202",
          email: "afriprog@gmail.com",
          ighandle: "iamthe_jabal",
          twitterHandle: "iamthe_jabal",
          name: "Rasheed Ashimolowo",
          nickName: "Al-Jabal",
          path: "2-pix-20211201751.png",
        },
        receiver: {
          phone: "2347069494849",
          email: null,
          ighandle: null,
          twitterHandle: null,
          name: "Temitayo Ojo",
          nickName: null,
          path: null,
        },
      },
      {
        id: 5,
        amount: 12000.0,
        createDate: "2021-10-07T07:58:07.423",
        eventId: 1,
        isOpen: false,
        note: "We say a big thank you",
        openDate: null,
        receiverId: 6,
        senderId: 2,
        sender: {
          phone: "2348055065202",
          email: "afriprog@gmail.com",
          ighandle: "iamthe_jabal",
          twitterHandle: "iamthe_jabal",
          name: "Rasheed Ashimolowo",
          nickName: "Al-Jabal",
          path: "2-pix-20211201751.png",
        },
        receiver: {
          phone: "234808891 1237",
          email: null,
          ighandle: null,
          twitterHandle: null,
          name: "Mr Balogun",
          nickName: null,
          path: null,
        },
      },
      {
        id: 1002,
        amount: 500.0,
        createDate: "2021-11-29T15:08:17.533",
        eventId: 1,
        isOpen: false,
        note: "We are waiting for the naming",
        openDate: null,
        receiverId: 3,
        senderId: 2,
        sender: {
          phone: "2348055065202",
          email: "afriprog@gmail.com",
          ighandle: "iamthe_jabal",
          twitterHandle: "iamthe_jabal",
          name: "Rasheed Ashimolowo",
          nickName: "Al-Jabal",
          path: "2-pix-20211201751.png",
        },
        receiver: {
          phone: "2347069494849",
          email: null,
          ighandle: null,
          twitterHandle: null,
          name: "Temitayo Ojo",
          nickName: null,
          path: null,
        },
      },
      {
        id: 1003,
        amount: 1490.0,
        createDate: "2021-12-06T12:40:29.067",
        eventId: 1,
        isOpen: false,
        note: "We wish you guys best",
        openDate: null,
        receiverId: 6,
        senderId: 2,
        sender: {
          phone: "2348055065202",
          email: "afriprog@gmail.com",
          ighandle: "iamthe_jabal",
          twitterHandle: "iamthe_jabal",
          name: "Rasheed Ashimolowo",
          nickName: "Al-Jabal",
          path: "2-pix-20211201751.png",
        },
        receiver: {
          phone: "234808891 1237",
          email: null,
          ighandle: null,
          twitterHandle: null,
          name: "Mr Balogun",
          nickName: null,
          path: null,
        },
      },
      {
        id: 1004,
        amount: 2000.0,
        createDate: "2021-12-06T13:06:50.88",
        eventId: 1,
        isOpen: false,
        note: "You mean alot to me",
        openDate: null,
        receiverId: 3,
        senderId: 2,
        sender: {
          phone: "2348055065202",
          email: "afriprog@gmail.com",
          ighandle: "iamthe_jabal",
          twitterHandle: "iamthe_jabal",
          name: "Rasheed Ashimolowo",
          nickName: "Al-Jabal",
          path: "2-pix-20211201751.png",
        },
        receiver: {
          phone: "2347069494849",
          email: null,
          ighandle: null,
          twitterHandle: null,
          name: "Temitayo Ojo",
          nickName: null,
          path: null,
        },
      },
      {
        id: 1008,
        amount: 1000.0,
        createDate: "2021-12-12T11:33:39.913",
        eventId: 1,
        isOpen: true,
        note: "Congratulations",
        openDate: "2021-12-12T13:19:57.69",
        receiverId: 2,
        senderId: 2,
        sender: {
          phone: "2348055065202",
          email: "afriprog@gmail.com",
          ighandle: "iamthe_jabal",
          twitterHandle: "iamthe_jabal",
          name: "Rasheed Ashimolowo",
          nickName: "Al-Jabal",
          path: "2-pix-20211201751.png",
        },
        receiver: {
          phone: "2348055065202",
          email: "afriprog@gmail.com",
          ighandle: "iamthe_jabal",
          twitterHandle: "iamthe_jabal",
          name: "Rasheed Ashimolowo",
          nickName: "Al-Jabal",
          path: "2-pix-20211201751.png",
        },
      },
      {
        id: 1009,
        amount: 1200.0,
        createDate: "2021-12-12T12:34:19.257",
        eventId: 1,
        isOpen: true,
        note: "Another oner",
        openDate: "2022-05-11T19:21:50.413",
        receiverId: 2,
        senderId: 2,
        sender: {
          phone: "2348055065202",
          email: "afriprog@gmail.com",
          ighandle: "iamthe_jabal",
          twitterHandle: "iamthe_jabal",
          name: "Rasheed Ashimolowo",
          nickName: "Al-Jabal",
          path: "2-pix-20211201751.png",
        },
        receiver: {
          phone: "2348055065202",
          email: "afriprog@gmail.com",
          ighandle: "iamthe_jabal",
          twitterHandle: "iamthe_jabal",
          name: "Rasheed Ashimolowo",
          nickName: "Al-Jabal",
          path: "2-pix-20211201751.png",
        },
      },
      {
        id: 2005,
        amount: 1500.0,
        createDate: "2021-12-19T01:17:02.48",
        eventId: 1,
        isOpen: true,
        note: "Special gift. I don't want to send envelope of 2k make e no resemble urgent 2k. Cheers",
        openDate: "2021-12-19T01:22:57.307",
        receiverId: 2,
        senderId: 1024,
        sender: {
          phone: "2348102031540",
          email: "detayur4real@gmail.com",
          ighandle: null,
          twitterHandle: null,
          name: "Adetayo Taiwo",
          nickName: null,
          path: "1024-pix-202112171025.png",
        },
        receiver: {
          phone: "2348055065202",
          email: "afriprog@gmail.com",
          ighandle: "iamthe_jabal",
          twitterHandle: "iamthe_jabal",
          name: "Rasheed Ashimolowo",
          nickName: "Al-Jabal",
          path: "2-pix-20211201751.png",
        },
      },
      {
        id: 3003,
        amount: 10000.0,
        createDate: "2022-05-18T21:40:15.353",
        eventId: 1,
        isOpen: false,
        note: "Token",
        openDate: null,
        receiverId: 2,
        senderId: 1006,
        sender: {
          phone: "2347036074909",
          email: "phatimatwahab@gmail.com",
          ighandle: null,
          twitterHandle: null,
          name: "Fatima Wahab",
          nickName: null,
          path: "1006-pix-20211208232.png",
        },
        receiver: {
          phone: "2348055065202",
          email: "afriprog@gmail.com",
          ighandle: "iamthe_jabal",
          twitterHandle: "iamthe_jabal",
          name: "Rasheed Ashimolowo",
          nickName: "Al-Jabal",
          path: "2-pix-20211201751.png",
        },
      },
    ],
    sprays: [
      {
        sprayerId: 2,
        nickName: "Al-Jabal",
        path: "2-pix-20211201751.png",
        eventId: 1,
        totalAmount: 87400.0,
      },
    ],
    totalinvite: 4,
    selfinvite: 1,
    willattend: 1,
    invites: [
      {
        createDate: "2021-09-21T14:56:43.66",
        createdBy: 2,
        dateInvited: "2021-09-21T14:56:43.64",
        didAttend: true,
        eventId: 1,
        id: 4,
        invitedBy: 2,
        isPublic: true,
        isSelfInvite: false,
        memberId: 3,
        seats: 2,
        seatTitle: "High table",
        selfNote: "",
        specs: "Special guest",
        willAttend: false,
        member: {
          name: "Temitayo Ojo",
          nickName: null,
          twitterHandle: null,
          specs: null,
          phone: "2347069494849",
          payments: [],
          ighandle: null,
        },
      },
    ],
    vendors: [
      {
        v: {
          businessName: "Mayor Cognisance",
          logo: null,
          id: 1,
          twitterHandle: "Mayorcognisance",
          address: "Owode Yewa",
          description: "Event planning and delivery of cakes and decoration",
        },
        agreedAmount: 175000.0,
        agreementNote:
          "Our wedding cake 3step with decoration and baloons. Can you make green baloons",
        cancelBy: null,
        cancelReason: null,
        createBy: 2,
        createDate: "2021-09-22T11:08:53.673",
        isCancel: null,
        isPublic: false,
        isVendorAccept: true,
        vendorAcceptNote: null,
        vendorId: 1,
        id: 1,
      },
      {
        v: {
          businessName: "QualityQue Farm",
          logo: null,
          id: 2,
          twitterHandle: "Qualitycuefarms",
          address: "Owode Yewa",
          description: "Bringing farm produce to your event, fresh from farm",
        },
        agreedAmount: 11200.0,
        agreementNote: null,
        cancelBy: null,
        cancelReason: null,
        createBy: 0,
        createDate: "2021-09-22T11:10:32.08",
        isCancel: null,
        isPublic: false,
        isVendorAccept: false,
        vendorAcceptNote: null,
        vendorId: 2,
        id: 2,
      },
      {
        v: {
          businessName: "King ?? planners",
          logo: null,
          id: 1002,
          twitterHandle: "Kineevents",
          address: "15 Bamidele Adebule St, Anthony 105102, Lagos, Nigeria",
          description: "Event planning",
        },
        agreedAmount: 140000.0,
        agreementNote:
          "We want your service on event planning but you did not set up your services",
        cancelBy: null,
        cancelReason: null,
        createBy: 2,
        createDate: "2021-12-17T06:23:54.06",
        isCancel: null,
        isPublic: false,
        isVendorAccept: false,
        vendorAcceptNote: null,
        vendorId: 1002,
        id: 11,
      },
    ],
    mainowner: {
      canAddImages: true,
      canAddOwner: true,
      canAddVendor: true,
      canInvite: true,
      canModify: true,
      canSeeInviteList: true,
      canUnInvite: true,
      canVerify: true,
      createDate: "2021-09-12T15:08:43.92",
      createdBy: 2,
      eventId: 1,
      id: 0,
      ownerAs: "Planner",
      isPublic: true,
      member: {
        name: "Rasheed Ashimolowo",
        nickName: "Al-Jabal",
        path: "2-pix-20211201751.png",
        twitterHandle: "iamthe_jabal",
        ighandle: "iamthe_jabal",
        specs: "CEO Justweb Technology",
        id: 2,
      },
    },
  },
};
